import * as Sentry from '@sentry/nextjs'
import type { GetStaticPropsContext, InferGetStaticPropsType } from 'next'
import { builder, Builder, BuilderComponent } from '@builder.io/react'
import { getAsyncProps } from '@builder.io/utils'
import { Layout } from '@/components'

import {
  getCategoryWithoutProducts,
  getCategoryWithProducts,
} from '@/features/categories/api/get-category'
import { NextSeo } from 'next-seo'

// TODO: Render this page with a react18 feature (either streaming SSR or server components to increase performance)
// https://nextjs.org/docs/advanced-features/react-18/overview
// https://nextjs.org/docs/advanced-features/react-18/streaming
function Home({ content }: InferGetStaticPropsType<typeof getStaticProps>) {
  if (!Builder.isEditing && !Builder.isPreviewing && !content) {
    // https://www.builder.io/c/docs/guides/preview-url
    // TODO: Add 404 view
    return <div>404</div>
  }
  return (
    <>
      <Layout>
        <BuilderComponent content={content} model={'page'} />
      </Layout>
      <NextSeo title={'ASSHOLES LIVE FOREVER'} />
    </>
  )
}

export async function getStaticProps(props: GetStaticPropsContext) {
  const content = await builder.get('page', { url: '/' }).promise()
  await getAsyncProps(content, {
    async CategoryProductTileSet({ categorySlug: slug, productCount }) {
      // todo: add above the fold meta prop to the very first tileset
      // https://github.com/BuilderIO/builder/tree/main/packages/utils#getasyncprops
      try {
        const { data } = await getCategoryWithProducts({ slug, productCount })
        return {
          data,
        }
      } catch (e) {
        Sentry.captureException(e)
        return {
          data: {},
        }
      }
    },

    async CategoryList({ slugs }) {
      // TODO: Type
      const categories = []

      for (const { slug } of slugs) {
        try {
          const { data } = await getCategoryWithoutProducts(slug)
          const category = data.site.route.node as any
          categories.push({
            id: category.entityId,
            name: category.name,
            image: category.defaultImage,
            slug,
          })
        } catch (e) {
          console.debug(e)
          Sentry.captureException(e)
        }
      }

      return {
        categories,
      }
    },
  })

  return {
    props: {
      content,
    },
    revalidate: 60 * 60 * 12,
  }
}

export default Home
